(function() {
	var reviewText = document.querySelectorAll('.js-reviewtext');
	var allText = [];

	//もっと見るをクリックした時
	var setClickBtnHandler = function() {
		var onButtonClick = function() {
			this.parentNode.innerHTML = '<p>' + allText[this.dataset.num] + '</p>';
		}

		for (var cnt=0, len=reviewText.length; cnt<len; ++cnt) {
			reviewText[cnt].nextSibling.dataset.num = cnt;
			reviewText[cnt].nextSibling.addEventListener('click', onButtonClick);
		}
	}

	//最初の表示
	document.addEventListener('DOMContentLoaded', function() {
		for(var cnt=0,len=reviewText.length; cnt<len; cnt++) {
			allText.push(reviewText[cnt].innerText);

			if(reviewText[cnt].innerText.length>125) {
				reviewText[cnt].innerHTML = reviewText[cnt].innerText.substr(0,125) + '…';
				reviewText[cnt].nextSibling.className = 'review-list-text-more';
			}
		}
		setClickBtnHandler() ;
	});

})();

var EPK = EPK || {};
EPK.Pref = EPK.Pref || {};
EPK.Pref.Common = function(){};
EPK.Pref.Common.PC_SHUFOO_CHIRASHI_MIN_NUM = 3;

EPK.Pref.Common.prototype.initialize = function(){
	var _this = this;
	var location_path = document.location.pathname;
	$('input[name=city_all]').attr('checked', false);
	$('input[name=city]').attr('checked', false);
	$('#detail_all').attr('checked', false);
	$('input[name=city_sel_all]').attr('checked', false);
	$('input[name=detail]').attr('checked', false);
};

EPK.Pref.Common.prototype.desableAllUnchecked = function($checked){
	this.desableUnchecked($('input[name=city_all]'), $checked);
	this.desableUnchecked($('input[name=city]'), $checked);
	this.desableUnchecked($('#detail_all'), $checked);
	this.desableUnchecked($('input[name=city_sel_all]'), $checked);
	this.desableUnchecked($('input[name=detail]'), $checked);
};
EPK.Pref.Common.prototype.desableUnchecked = function($target, $checked) {
	$target.each(function(){
		var $this = $(this);
		if($this.get(0) !== $checked.get(0)){
			$this.attr('disabled', 'disabled');
		}
	})
};

EPK.Pref.Common.prototype.calcShopCount = function(elem, btnSearch, btnSelect, $checkedElement, opt_redirectFlag) {
	this.desableAllUnchecked($checkedElement);
	var shop_count = 0;
	var area_ids = [];
	var city_ids = [];
	var query = '';
	var sel_query = '';
	var target_url = '';
	var select_target_url = '';
	var str = '';
	var mode = ($('#mode').val()!="")?1:0;
	var change_place = ($('#change_place').val()!="")?1:0;
	var elem_designated = $('input[name=city_all]');
	var designated_checked = 0;
	var elem_designated_name = [];
	var km_brand_page_id = ($('#km_brand_page_id').val()!="")?$('#km_brand_page_id').val():'';
	var arr = (location.search!="")?location.search.replace('?','').split('&'):[];
	if(typeof opt_redirectFlag == "undefined"){
		opt_redirectFlag = true;
	}

	for(var i=0;i<arr.length;i++){
		var tmp = arr[i].split('=');
		if((tmp[0]!='area_id[]')&&(tmp[0]!='line_cd[]')&&(tmp[0]!='station_cd[]')&&(tmp[0]!='pref_cd')&&(tmp[0]!='mode')&&(tmp[0]!='zip_code')&&(tmp[0]!='map')&&(tmp[0]!='km_brand_page_id')&&(tmp[0]!='is_designatedcity')&&(tmp[0]!='designatedcity_name')){
			query = query+((query=='')?'?':'&')+arr[i];
			sel_query = sel_query+((sel_query=='')?'?':'&')+arr[i];
		}
	}

	//政令指定都市
	elem_designated.each(function(){
		if($(this).prop('checked')==true){
			designated_checked = designated_checked + 1;
			elem_designated_name.push($(this).attr('key'));
		}
	});

	var desinated_name = elem_designated_name[0] || "";
	var checked_count = 0;
	elem.each(function(){
		if($(this).prop('checked')){
			shop_count += parseInt($(this).attr('value'));
			area_ids.push($(this).attr('id'));
			city_ids.push($(this).attr('city_id'));
			checked_count++;
		}
	});

	$('#shop_count').text(shop_count);
	if(shop_count > 0){
		if(checked_count > 1){
			btnSelect.addClass('is-disable-gray').removeClass('is-white');
			btnSearch.addClass('is-disable-gray');
		}else{
			btnSelect.removeClass('is-disable-gray').addClass('is-white');
			btnSearch.removeClass('is-disable-gray');
		}
		if(area_ids.length == 1){
			if(mode){
				target_url = '/condition'+query+((query=='')?'?':'&')+'area_id[]='+area_ids[0]+'&pref_cd='+$('#pref_key').attr('value')+'&mode='+$('#mode').attr('value');
				select_target_url = '/select/detail'+query+((query=='')?'?':'&')+'area_id[]='+area_ids[0]+'&pref_cd='+$('#pref_key').attr('value')+'&mode='+$('#mode').attr('value');
			} else if(change_place){
				target_url = '/'+$('#pref_key').attr('value')+'/list/'+area_ids[0]+query;
				select_target_url = '/'+$('#pref_key').attr('value')+'/city/list/'+city_ids[0]+query;
			} else {
				target_url = '/'+$('#pref_key').attr('value')+'/list/'+area_ids[0];
				select_target_url = '/'+$('#pref_key').attr('value')+'/city/list/'+city_ids[0];
			}
		} else {
			for(i=0;i<area_ids.length;i++){
				if(i==0){
					str = (query=="")?'?':'&';
				} else {
					str = '&'
				}
				query = query + str+"area_id[]="+area_ids[i];
				sel_query = sel_query + str+"city_cd[]="+city_ids[i];
			}
			if(mode){
				if(designated_checked==1){
					target_url = '/condition'+query+((query=='')?'?':'&')+'pref_cd='+$('#pref_key').attr('value')+'&is_designatedcity=1&designatedcity_name='+desinated_name+'&mode='+$('#mode').attr('value');
				} else {
					target_url = '/condition'+query+((query=='')?'?':'&')+'pref_cd='+$('#pref_key').attr('value')+'&mode='+$('#mode').attr('value');
				}
				select_target_url = '/select/detail'+query+((query=='')?'?':'&')+'pref_cd='+$('#pref_key').attr('value')+'&mode='+$('#mode').attr('value');
			} else if(change_place){
				if(designated_checked==1){
					target_url = '/'+$('#pref_key').attr('value')+'/list/'+desinated_name+"/"+ query;
				} else {
					target_url = '/'+$('#pref_key').attr('value')+'/list'+ query;
				}
				select_target_url = '/'+$('#pref_key').attr('value')+'/city/list/'+ query + sel_query;
			} else {
				if(designated_checked==1){
					target_url = '/'+$('#pref_key').attr('value')+'/list/'+desinated_name;
				} else {
					target_url = '/'+$('#pref_key').attr('value')+'/list'+ query;
				}
				select_target_url = '/'+$('#pref_key').attr('value')+'/city/list'+ sel_query;
			}
		}
		if (km_brand_page_id != "") {
			select_target_url = '/brand/'+km_brand_page_id+select_target_url;
			target_url = '/brand/'+km_brand_page_id+target_url;
		}
		if((km_brand_page_id!="")&&(select_target_url.indexOf('km_brand_page_id=')==-1)){
			//select_target_url = select_target_url+((select_target_url.indexOf('?')!=-1)?'&':'?')+'km_brand_page_id='+km_brand_page_id;
			//target_url = target_url+((target_url.indexOf('?')!=-1)?'&':'?')+'km_brand_page_id='+km_brand_page_id;
			select_target_url = select_target_url;
			target_url = target_url;
		}
		btnSelect.attr("href", select_target_url);
		btnSearch.attr("href", target_url);
		window.location.href = target_url;
	} else {
		btnSelect.removeClass('is-white').addClass('is-disable-gray');
		btnSelect.attr("href", "javascript:void(0);");
		btnSearch.addClass('is-disable-gray');
		btnSearch.attr("href", "javascript:void(0);");
	}

	return false;
};

/**
 * TODO: ブランドurl
 * @param designated_city
 */
EPK.Pref.Common.prototype.redirectDesignated = function(designated_city) {
	var query = '';
	var arr = (location.search!="")?location.search.replace('?','').split('&'):[];
	var change_place = ($('#change_place').val()!="")?1:0;
	var target_url ="";
	var km_brand_page_id = ($('#km_brand_page_id').val()!="")?$('#km_brand_page_id').val():'';

	for(i=0;i<arr.length;i++){
		var tmp = arr[i].split('=');
		if((tmp[0]!='area_id[]')&&(tmp[0]!='line_cd[]')&&(tmp[0]!='station_cd[]')&&(tmp[0]!='pref_cd')&&(tmp[0]!='mode')&&(tmp[0]!='zip_code')&&(tmp[0]!='map')){
			query = query+((query=='')?'?':'&')+arr[i];
		}
	}
	if(change_place){
		target_url = '/'+$('#pref_key').attr('value')+'/list/'+designated_city+ query;
	} else {
		target_url = '/'+$('#pref_key').attr('value')+'/list/'+designated_city;
	}
	if (km_brand_page_id != "") {
		target_url = '/brand/'+km_brand_page_id+target_url;
	}
	window.location.href = target_url;
};

EPK.Pref.Common.prototype.calcShopCountDetail = function(elem, btnSearch, $checkedElement) {
	this.desableAllUnchecked($checkedElement);
	var shop_count = 0;
	var area_ids = [];
	var query = '';
	var sel_query = '';
	var target_url = '';
	var select_target_url = '';
	var str = '';
	var mode = ($('#mode').val()!="")?1:0;
	var change_place = ($('#change_place').val()!="")?1:0;
	var km_brand_page_id = ($('#km_brand_page_id').val()!="")?$('#km_brand_page_id').val():'';
	var arr = (location.search!="")?location.search.replace('?','').split('&'):[];
	var sel_query = '';
	for(i=0;i<arr.length;i++){
		var tmp = arr[i].split('=');
		if((tmp[0]!='area_id[]')&&(tmp[0]!='line_cd[]')&&(tmp[0]!='station_cd[]')&&(tmp[0]!='pref_cd')&&(tmp[0]!='mode')&&(tmp[0]!='zip_code')&&(tmp[0]!='map')){
			query = query+((query=='')?'?':'&')+arr[i];
			sel_query = sel_query+((sel_query=='')?'?':'&')+arr[i];
		}
	}
	elem.each(function(){
		if($(this).prop('checked')){
			shop_count += parseInt($(this).attr('value'));
			area_ids.push($(this).attr('id'));
		}
	});
	$('#shop_count').text(shop_count);
	if(shop_count > 0){
		btnSearch.removeClass('is-disable-gray');
		if(area_ids.length == 1){
			if(mode){
				target_url = '/condition'+query+((query=='')?'?':'&')+'pref_cd='+$('#pref_key').attr('value')+'&area_id[]='+area_ids[0]+'&mode='+$('#mode').attr('value');
			} else if(change_place){
				target_url = '/'+$('#pref_key').attr('value')+'/list/'+area_ids[0]+ query;
			} else {
				target_url = '/'+$('#pref_key').attr('value')+'/list/'+area_ids[0];
			}
		} else {
			for(i=0;i<area_ids.length;i++){
				if(i==0){
					str = (query!="")?'&':'?';
				} else {
					str = '&'
				}
				query = query+str+"area_id[]="+area_ids[i];
			}
			if(mode){
				target_url = '/condition'+query+((query=='')?'?':'&')+'pref_cd='+$('#pref_key').attr('value')+'&mode='+$('#mode').attr('value');
			} else if(change_place){
				target_url = '/'+$('#pref_key').attr('value')+'/list'+ query;
			} else {
				target_url = '/'+$('#pref_key').attr('value')+'/list'+ query;
			}
		}
		if (km_brand_page_id != "") {
			target_url = '/brand/'+km_brand_page_id+target_url;
		}
		if((km_brand_page_id!="")&&(target_url.indexOf('km_brand_page_id=')==-1)){
			//target_url = target_url+((target_url.indexOf('?')!=-1)?'&':'?')+'km_brand_page_id='+km_brand_page_id;
			target_url = target_url;
		}
		btnSearch.attr("href", target_url);
		window.location.href = target_url;
	} else {
		btnSearch.addClass('is-disable-gray');
		btnSearch.attr("href", "javascript:void(0);");
	}

	return false;
};

//該当条件の薬局件数を取得
EPK.Pref.Common.prototype.calcShopCountCond = function(elem, btnSearch, $checkedElement) {
	var shop_count = 0;
	var query_ids = [];
	var query_names = [];
	var query = "";
	var target_url = '/s';
	var str = '';
	var area_count = 0;
	var station_count = 0;
	var area_id = "";
	var station_cd = "";
	var ajax_query = "";

	var arr = (location.search!="")?location.search.replace('?','').split('&'):[];
	for(i=0;i<arr.length;i++){
		var tmp = arr[i].split('=');
		if(tmp[0]=='station_cd[]'){ station_count++; station_cd=tmp[1]; }
		if(tmp[0]=='area_id[]'){ area_count++; area_id=tmp[1]; }
		if((tmp[0]!='keyword')&&(tmp[0]!='pref_cd')&&(tmp[0]!='line_cd[]')&&(tmp[0]!='area_id[]')&&(tmp[0]!='station_cd[]')&&(tmp[0]!='mode')&&(tmp[0]!='p')){
			query = query+'&'+arr[i];
		}
		if((tmp[0]!='keyword')&&(tmp[0]!='pref_cd')&&(tmp[0]!='mode')){
			ajax_query = ajax_query+'&'+arr[i];
		}
	}
	elem.each(function(){
		var cond = $(this).attr('name')+'='+$(this).attr('value');
		var pos = query.indexOf(cond);
		var sep = "";
		if(pos > 0){
			sep = query.substring((pos-1), pos);
			query = query.replace(sep+cond,'');
			ajax_query = ajax_query.replace(sep+cond,'');
		}
		if($(this).prop('checked')){
			query = query+'&'+cond;
			ajax_query = ajax_query+'&'+cond;
		}
	});
	if(area_count==1){
		target_url = "/"+$('#pref_key').val()+"/list/"+area_id;
	}
	if(station_count==1){
		target_url = "/"+$('#pref_key').val()+"/line/station/"+station_cd;
	}
	if($('#pref_key').val() !=""){
		if((area_count!=1)&&(station_count!=1)){
			ajax_query = ajax_query+'&'+'pref_cd='+$('#pref_key').val();
			query = query+'&'+'pref_cd='+$('#pref_key').val();
		} else {
			ajax_query = ajax_query+'&'+'pref_cd='+$('#pref_key').val();
		}
	}
	if($('#keyword').length>0){
		if($('#keyword').val() !=""){
			query = query+'&'+'keyword='+$('#keyword').val();
			ajax_query = ajax_query+'&'+'keyword='+$('#keyword').val();
		}
	}
	if($('#km_brand_page_id').length>0){
		if($('#km_brand_page_id').val() !=""){
			//query = query+'&'+'km_brand_page_id='+$('#km_brand_page_id').val();
			//ajax_query = ajax_query+'&'+'km_brand_page_id='+$('#km_brand_page_id').val();
			query = query;
			ajax_query = ajax_query;
		}
	}
	query = ((query.substring(0, 1)== "&")?'?':'?')+query.substring(1, query.length);
	query = (query.length==1)?'':query;
	ajax_query = ((ajax_query.substring(0, 1)== "&")?'?':'?')+ajax_query.substring(1, ajax_query.length);
	ajax_query = (ajax_query.length==1)?'':ajax_query;

	var shop_count=0;
	$.ajax({
		type: 'get',
		dataType: 'json',
		url: '/ajax/condition'+ajax_query,
		cache: false,
		timeout: 60000
	}).done(function(data){
		if(data > 0) {
			btnSearch.removeClass('is-disable');
			btnSearch.removeClass('is-disable-gray');
			btnSearch.attr("href", target_url+query);
			$('#shop_count').text(data);
		} else {
			btnSearch.addClass('is-disable');
			btnSearch.addClass('is-disable-gray');
			btnSearch.attr("href", "javascript:void(0);");
			$('#shop_count').text(0);
		}
	}).fail(function(){
		// アクション側でExceptionが投げられた場合はここに来る。
		// エラーをここで処理したい場合はExceptionを投げても良い
	});
};

/**
 * 全国/各都道府県の新着チラシ情報非同期取得
 */
EPK.Pref.getShufooChirashiList = function() {
	var pref_cd = EPK.common.prefCD;

	$.ajax({
		type: 'POST',
		url: '/api/prefs/get_shufoo_chirashi_list?pref_cd=' + pref_cd,
		data: [],
		cache: false,
		dataType: 'json',
		timeout: 100000
	})
	.done(function(data) {
		if(data['result'] == 'success'){
			EPK.Pref.Common.prototype.renderShufooChirashiList(data);

		}else if(data['result'] == 'no_data'){
			return false;
		}

		return true;
	}).fail(function(data){
		console.log('failed');
		console.log(data);
		return false;
	});

	return true;
};
/**
 * shufooのチラシ情報描画処理
 * @method renderShufooChirashiList
 * @param {Object} shufooInfo API:get_shufoo_chirashi_listの戻り値
 */
EPK.Pref.Common.prototype.renderShufooChirashiList = function (shufooInfo) {
	if (!shufooInfo) {
		return;
	}
	var prefName = shufooInfo.pref_data ? (shufooInfo.pref_data.name || '') : '';
	var chirashiList = shufooInfo.shufoo_chirashi_list || [];
	if (EPK.isMobilePref) {
		// SP版の表示処理
		EPK.Pref.Common.prototype.renderSpShufooChirashi(chirashiList, prefName);
		return;
	}
	EPK.Pref.Common.prototype.renderPcShufooChirashi(chirashiList, prefName);

};
/**
 * SP用のshuffoチラシ表示
 * @method renderSpShufooChirashi
 * @param {Array.Object} chirashiList
 * @param {String} prefName
 */
EPK.Pref.Common.prototype.renderSpShufooChirashi = function (chirashiList, prefName) {
	var spChirashiLen = chirashiList ? chirashiList.length : 0;
	// チラシデータがない場合は描画しない
	if (!spChirashiLen) {
		return;
	}
	var parentEl = document.getElementById('disp_shufoo_chirashi_list_area');
	if (!parentEl) return;

	// チラシ用のエレメントを作成
	var chirashiAreaEl = '<div class="sp-contents-box-wide"><div class="sp-flyer-content-block"><div class="sp-flyer-content">';
	chirashiAreaEl += '<h2>' + (prefName || "全国") + 'の新着チラシ情報</h2>';
	chirashiAreaEl += '<div id="chirashi-swiper_sp" class="swiper-sp-flyer-content-body clearfix swiper-container-horizontal">';
	chirashiAreaEl += '<ul class="sp-flyer-content-body swiper-wrapper">';
	// チラシリストの要素を作成
	var chirashiEls = '';
	for (var i = 0; i < spChirashiLen; i++) {
		var data = chirashiList[i];
		var shopId = data.shop_id || "";
		var title = data.title || "";
		var prefFuri = data.pref_furi || '';
		var companyName = data.company_name || '';
		var url = prefFuri && shopId ? '/' + prefFuri + '/s' + shopId + '/coupon' : "";
		var aElClass = (prefName ? 'GA_preftop_chirashi_' : 'GA_top_chirashi_') + shopId;
		chirashiEls += '<li class="swiper-slide">';
		chirashiEls += '<a href="' + url + '" class="' + aElClass + '">';
		chirashiEls += '<div><img class="lazyload" data-src="' + (data.thumbnail_url || "") + '" alt="' + title + '"></div>';
		chirashiEls += '<p class="name">' + (data.shop_name || "") + '</p>';
		chirashiEls += '<span>' + title + '</span>';

		if(companyName != ''){
			chirashiEls += '<p class="flyer-info-list-by">by ' + companyName + '</p>';
		}

		chirashiEls += '</a></li>';
	}
	chirashiAreaEl += chirashiEls;
	chirashiAreaEl += '</ul></div></div></div></div>';

	parentEl.insertAdjacentHTML('beforeend', chirashiAreaEl);
	EPK.Pref.Common.prototype.createSpChirashiSwiper();

};
/**
 * SP用のチラシスライダー作成
 * @method createSpChirashiSwiper
 */
EPK.Pref.Common.prototype.createSpChirashiSwiper = function() {
	// スライダー作成
	$('#chirashi-swiper_sp').swiper({
		scrollbarHide: true,
		slidesPerView: 'auto',
		centeredSlides: true,
		spaceBetween: 0,
		grabCursor: true,
		loop: true,
	});
};
/**
 * PC用のshuffoチラシ表示
 * @method renderPcShufooChirashi
 * @param {Array.Object} chirashiList
 * @param {String} prefName
 */
EPK.Pref.Common.prototype.renderPcShufooChirashi = function(chirashiList, prefName) {
	var pcChirashiLen = chirashiList ? chirashiList.length : 0;
	// チラシデータがない場合は描画しない
	if (!pcChirashiLen) {
		return;
	}
	var parentEl = document.getElementById('disp_shufoo_chirashi_list_area');
	if (!parentEl) return;

	// チラシ用のエレメントを作成
	var chirashiAreaEl = '<div class="flyer-info"><div class="flyer-info-ttl">';
	chirashiAreaEl += '<h2>' + (prefName || "全国") + 'の新着チラシ情報</h2>';
	chirashiAreaEl += '</div><div class="flyer-info-slider"><div class="swiper">';
	chirashiAreaEl += pcChirashiLen > EPK.Pref.Common.PC_SHUFOO_CHIRASHI_MIN_NUM ? '<div class="swiper-container" id="chirashi-swiper_pc">' : '<div class="swiper-container pref-info-flyer1-3">';
	chirashiAreaEl += '<div class="swiper-wrapper">';

	// チラシリストの要素を作成
	var chirashiEls = '';
	for (var i = 0; i < pcChirashiLen; i++) {
		var data = chirashiList[i];
		var shopId = data.shop_id || "";
		var title = data.title || "";
		var prefFuri = data.pref_furi || '';
		var companyName = data.company_name || '';

		var url = prefFuri && shopId ? '/' + prefFuri + '/s' + shopId + '/coupon' : "";
		var aElClass = (prefName ? 'GA_preftop_chirashi_' : 'GA_top_chirashi_') + shopId;

		chirashiEls += '<div class="swiper-slide flyer-info-list">';
		chirashiEls += '<a href="'+url+'" class="'+aElClass+'">';
		chirashiEls += '<div class="flyer-info-list-img"><img class="lazyload" data-src="'+(data.thumbnail_url || "")+'" alt="'+title+'"></div>';
		chirashiEls += '<h3 class="flyer-info-list-ttl">'+(data.shop_name || "")+'</h3>';
		chirashiEls += '<p class="flyer-info-list-access">'+title+'</p>';

		if(companyName != ''){
			chirashiEls += '<p class="flyer-info-list-by">by ' + companyName + '</p>';
		}

		chirashiEls += '</a></div>';
	}
	chirashiAreaEl += chirashiEls;
	chirashiAreaEl += '</div></div></div>';

	if(pcChirashiLen > EPK.Pref.Common.PC_SHUFOO_CHIRASHI_MIN_NUM) {
		chirashiAreaEl += '<div class="flyer-info-pagebtn">';
		chirashiAreaEl += '<div class="swiper-pagination"></div>';
		chirashiAreaEl += '<span class="swiper-button-prev flyer-info-prev flyer-info-pagebtn-arrow"></span> <span class="swiper-button-next flyer-info-next flyer-info-pagebtn-arrow"></span>';
		chirashiAreaEl += '</div>';
	  }
	chirashiAreaEl += '</div></div>';

	parentEl.insertAdjacentHTML('beforeend', chirashiAreaEl);
	EPK.Pref.Common.prototype.createPcChirashiSwiper();
};
/**
 * PC用のチラシスライダー作成
 * @method createPcChirashiSwiper
 */
EPK.Pref.Common.prototype.createPcChirashiSwiper = function() {
	// スライダー作成
	$('#chirashi-swiper_pc').swiper({
		initialSlide: 0,
		loop: true,
		slidesPerView: 4,
		spaceBetween: 10,
		pagination: '.swiper-pagination',
		nextButton: '.flyer-info-next',
		prevButton: '.flyer-info-prev',
		autoplay: '5000',
	});
};

/**
 * くすりの窓口ポイント取得/画面へのセット
 * @method setKmPoint
 */
EPK.Pref.setKmPoint = function() {
	// PC版のみ
	if(EPK.isMobilePref){
		return true;
	}

	// ログイン時のみ
	if(!EPK.common.isLogin){
		return true;
	}

	$.ajax({
		type: 'POST',
		url: '/api/kmpoint/summary',
		data: {},
		cache: false,
		dataType: 'json',
		timeout: 80000
	})
	.done(function(data) {
		if (data.result['status'] == 'success') {
			if (data.result['km_point']) {
				// メニュー
				var km_point = data.result['km_point'].available_point;
				if (!km_point) {
					km_point = '0';
				}
				$('#set-km-point').append(km_point.toLocaleString());
			}
		} else {
			$('#set-km-point').text('-');
			console.log('kmPoint summary data is empty');
			return false;
		}
		return true;
	}).fail(function(data) {
		console.log('failed');
		console.log(data);
		return false;
	});
};

/**
 * 数値のカンマ編集
 */
EPK.Pref.numSeparate = function(num) {
	num = String(num);

	var strMinus = '';
	if (num.indexOf('-') > -1) {
		strMinus = '-';
	}

	num = num.replace(/-/g, '');
	var len = num.length;

	if (len > 3) {
		// 前半を引数に再帰呼び出し + 後半3桁
		return strMinus + this.numSeparate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
	} else {
		return strMinus + num;
	}
};

/**
 * #11451
 * PC版TOPスライダー
 */
EPK.Pref.specialBannerSlide = function () {
	var specialBnEl = document.getElementById('special_bn');
	var sliderEl = document.getElementById('special_slide_contents');
	if (!specialBnEl) {
		return;
	}
	if (sliderEl) {
		$('#special_slide_contents').swiper({
			//slidesPerView: 2,
			//slidesPerColumn: 1,
			//slidesPerGroup: 2,
			slidesPerView: "auto",
			spaceBetween: 17,
			nextButton: ".special-bn-next",
			prevButton: ".special-bn-prev",
			loop: true,
			autoplayDisableOnInteraction: false,
			autoplay: 5000,
		});

		var slider = document.querySelectorAll('#special_bn li.swiper-slide');
		for (var i = 0; i < slider.length; i++) {
			slider[i].style.width = "";
			}
	}
}

$(function(){
	var Pref = new EPK.Pref.Common();
	Pref.initialize();

	// 北海道・東北地方ブロックのクリック
	$('#blockHokkai').on('click', function(){
		$("#sp-pref-select-tohoku").animate({'left':0}, 250);
		return false;
	});
	// 関東地方ブロックのクリック

	$('#blockKanto').on('click', function(){
		$("#sp-pref-select-kanto").animate({'left':0}, 250);
		return false;
	});

	$('#blockKoshin').on('click', function(){
		$("#sp-pref-select-koshin").animate({'left':0}, 250);
		return false;
	});

	$('#blockTokai').on('click', function(){
		$("#sp-pref-select-tokai").animate({'left':0}, 250);
		return false;
	});

	$('#blockKansai').on('click', function(){
		$("#sp-pref-select-kansai").animate({'left':0}, 250);
		return false;
	});

	$('#blockChugoku').on('click', function(){
		$("#sp-pref-select-chugoku").animate({'left':0}, 250);
		return false;
	});

	$('#blockShikoku').on('click', function(){
		$("#sp-pref-select-shikoku").animate({'left':0}, 250);
		return false;
	});

	$('#blockKyushu').on('click', function(){
		$("#sp-pref-select-kyushu").animate({'left':0}, 250);
		return false;
	});

	// 地方ブロックからの戻り
	$('.link-to_zenkoku').on('click', function(){
		$(".sp-pref-select").animate({'left':'110%'}, 250);
		return false;
	});

	// 市区選択画面でのすべて選択のクリック
	$('input[name=city_all]').on('change', function() {
		var $this = $(this);
		var checked = this.checked;
		Pref.redirectDesignated($this.attr("key"));
	});

	// 市区選択画面でのクリック
	$('input[name=city]').on('change', function() {
		var $this = $(this);
		var length = $('input[name=city]:checked').length;
		var $btnSearch = $('#btnSearch');

		if($('input[name=detail]').length > 0){
			var checked = this.checked;
			$('input[name=detail]').each(function(){
				if($this.attr('parent_id') == $this.attr('id')) {
					$this.prop('checked', checked);
				}
			});
			Pref.calcShopCountDetail($('input[name=detail]'), $btnSearch, $this);
		} else {
			var parent_checked = $(this).prop('checked')==true ? false : true;
			var all_checked = 0;
			var child_count = 0;
			var parent_id = $(this).attr('parent_id');
			var objChecked = $(this);
			$('input[name=city]').each(function(){
				if (!$this.parent().hasClass("is-noclick") && (parent_id==$this.attr('parent_id'))) {
					child_count = child_count + 1;
					if($this.prop('checked') != parent_checked){
						if(parent_checked) { all_checked = 0;} else {all_checked = all_checked + 1;}
					} else {
						if(!parent_checked) { all_checked = 0;} else {all_checked = all_checked + 1;}
					}
				}
			});
			$('input[name=city_all]').each(function(){
				if(objChecked.attr('parent_id') == $this.attr('id')) {
					$this.prop('checked', (all_checked >= child_count?true:false));
				}
			});
			Pref.calcShopCount($('input[name=city]'),$btnSearch,$btnSearch, $this, false);
		}
	});

	// 市区選択画面でのすべて選択のクリック
	$('#detail_all').on('change', function() {
		var $this = $(this);
		$('input[name=city]').prop('checked', this.checked);
		$('input[name=city_sel_all]').prop('checked', this.checked);
		$('input[name=detail]').prop('checked', this.checked);
		Pref.calcShopCountDetail($('input[name=detail]'),$('#btnSearch'), $this);
	});
	// 市区選択画面でのすべて選択のクリック
	$('input[name=city_sel_all]').on('change', function() {
		$('input[name=city]').prop('checked', this.checked);
		var $this = $(this);
		var checked = this.checked;
		$('input[name=detail]').each(function(){
			if($this.attr('parent_id') == $this.attr('id')) {
				$this.prop('checked', checked);
			}
		});
		Pref.calcShopCountDetail($('input[name=detail]'),$('#btnSearch'), $this);
	});
	// 市区選択画面でのすべて選択のクリック
	$('input[name=detail]').on('change', function() {
		var $this = $(this);
		Pref.calcShopCountDetail($('input[name=detail]'),$('#btnSearch'), $this);
	});


	//再検索のチェックボックス
	$('.research_check').on('change', function() {
		var $this = $(this);
		Pref.calcShopCountCond($('.research_check'), $('#btnReSearch'), $this);
	});
	//再検索のチェックボックス
	$('#keyword').on('change paste keyup', function() {
		var $this = $(this);
		Pref.calcShopCountCond($('.research_check'), $('#btnReSearch'), $this);
	});

	$("#brand-slides").swiper({
		scrollbarHide: true,
		slidesPerView: 1,
		centeredSlides: false,
		spaceBetween: 15,
		grabCursor: true,
		preloadImages: true,
		paginationClickable: true,
		pagination: ".swiper-pagination",
		nextButton: ".swiper-button-next",
		prevButton: ".swiper-button-prev",
		loop: true
	});

	if ($('#is_pc').length > 0 && $('#is_pc').val() == 1) {
		// custom scrollbar load
		$(window).on("load",function(){
			$.mCustomScrollbar.defaults.scrollButtons.enable=true; // enable scrolling buttons by default
			$("#content-l").mCustomScrollbar();
			$("#content-d").mCustomScrollbar({theme:"dark"});
			$("#content-l2").mCustomScrollbar({theme:"light-2"});
			$("#content-d2").mCustomScrollbar({theme:"dark-2"});
			$("#content-l3").mCustomScrollbar({theme:"light-3"});
			$("#content-d3").mCustomScrollbar({theme:"dark-3"});
			$("#content-ltk").mCustomScrollbar({theme:"light-thick"});
			$("#content-dtk").mCustomScrollbar({theme:"dark-thick"});
			$("#content-ltn").mCustomScrollbar({theme:"light-thin"});
			$("#content-dtn").mCustomScrollbar({theme:"dark-thin"});
			$("#content-m").mCustomScrollbar({theme:"minimal"});
			$("#content-md").mCustomScrollbar({theme:"minimal-dark"});
			$("#content-i").mCustomScrollbar({theme:"inset"});
			$("#content-id").mCustomScrollbar({theme:"inset-dark"});
			$("#content-i2").mCustomScrollbar({theme:"inset-2"});
			$("#content-i2d").mCustomScrollbar({theme:"inset-2-dark"});
			$("#content-i3").mCustomScrollbar({theme:"inset-3"});
			$("#content-i3d").mCustomScrollbar({theme:"inset-3-dark"});
			$("#content-r").mCustomScrollbar({theme:"rounded"});
			$("#content-rd").mCustomScrollbar({theme:"rounded-dark"});
			$("#content-rds").mCustomScrollbar({theme:"rounded-dots"});
			$("#content-rdsd").mCustomScrollbar({theme:"rounded-dots-dark"});
			$("#content-3d").mCustomScrollbar({theme:"3d"});
			$("#content-3dd").mCustomScrollbar({theme:"3d-dark"});
			$("#content-3dt").mCustomScrollbar({theme:"3d-thick"});
			$("#content-3dtd").mCustomScrollbar({theme:"3d-thick-dark"});
		});
	}

	//サジェスト初期化
	EPK.Suggest.init(EPK.common.params);

	$('#new_shops_slider').swiper({
		initialSlide: 0,
		loop: true,
		slidesPerView: 4,
		spaceBetween: 10,
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
		autoplay: '5000',
	});
	$('#store-swiper').swiper({
		scrollbarHide: true,
		slidesPerView: 'auto',
		centeredSlides: true,
		spaceBetween: 10,
		grabCursor: true,
		loop: true,
	});
	var showFlag = false;
	var topBtn = $('#go-to-top');
	topBtn.css('bottom', '-100px');

	//スクロールが100に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			if (showFlag == false) {
				showFlag = true;
				topBtn.stop().animate({'bottom' : '20px'}, 200);
			}
		} else {
			if (showFlag) {
				showFlag = false;
				topBtn.stop().animate({'bottom' : '-100px'}, 200);
			}
		}
	});

	//スクロールしてトップ
	topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});

	// 「前回の予約履歴から薬局を探す」を非同期取得
	EPK.ReserveHistory.getReservationHistory();

	// 全国/各都道府県の新着チラシ情報非同期取得
	EPK.Pref.getShufooChirashiList();

	// くすりの窓口ポイント取得/画面へのセット
	EPK.Pref.setKmPoint();

	// 特集バナースライダー
	EPK.Pref.specialBannerSlide();
});

EPK.Pref.renderBtnList = function (historyFlag) {

	var btnList = {
		'reserve-select-category01':{
			html: '<li class="category01">' +
						'<input type="radio" name="reserve-select-category" id="reserve-select-category01">' +
						'<label for="reserve-select-category01">' +
							'<div class="image">' +
								'<img src="/mobile/img/detail/img_reserve_select_sp_01.png" alt="受付カウンター">' +
							'</div>' +
							'<div class="category-title">' +
								'<p class="icon">店内</p>' +
								'<p class="title">受付カウンター</p>' +
							'</div>' +
						'</label>' +
					'</li>',
			target:true,
		},

		'reserve-select-category02':{
			html:'<li class="category02">' +
					'<input type="radio" name="reserve-select-category" id="reserve-select-category02">' +
					'<label for="reserve-select-category02">' +
						'<div class="image">' +
							'<img src="/mobile/img/detail/img_reserve_select_sp_02.png" alt="ドライブスルー">' +
						'</div>' +
						'<div class="category-title">' +
							'<p class="icon">店外</p>' +
							'<p class="title">ドライブスルー</p>' +
						'</div>' +
					'</label>' +
				'</li>',
			target:true,
		},

		'reserve-select-category03':{
			html:'<li class="category03">' +
					'<input type="radio" name="reserve-select-category" id="reserve-select-category03">' +
					'<label for="reserve-select-category03">' +
						'<div class="image">' +
							'<img src="/mobile/img/detail/img_reserve_select_sp_03.png" alt="駐車場で受取る">' +
						'</div>' +
						'<div class="category-title">' +
							'<p class="icon">店外</p>' +
							'<p class="title">駐車場で受取る</p>' +
						'</div>' +
					'</label>' +
				'</li>',
			target:true,
		},

		'reserve-select-category04':{
			html:'<li class="category04">' +
					'<input type="radio" name="reserve-select-category" id="reserve-select-category04">' +
					'<label for="reserve-select-category04">' +
						'<div class="image">' +
							'<img src="/mobile/img/detail/img_reserve_select_sp_04.png" alt="宅配">' +
						'</div>' +
						'<div class="category-title">' +
							'<p class="icon">店外</p>' +
							'<p class="title">宅配</p>' +
						'</div>' +
					'</label>' +
				'</li>',
			target:true,
		},
	}

	var btnHistoryList = {
		'reserve-select-category01-history':{
			html: '<li class="category01">' +
						'<input type="radio" name="reserve-select-category-history" id="reserve-select-category01-history">' +
						'<label for="reserve-select-category01-history">' +
							'<div class="image">' +
								'<img src="/mobile/img/detail/img_reserve_select_sp_01.png" alt="受付カウンター">' +
							'</div>' +
							'<div class="category-title">' +
								'<p class="icon">店内</p>' +
								'<p class="title">受付カウンター</p>' +
							'</div>' +
						'</label>' +
					'</li>',
			target:true,
		},

		'reserve-select-category02-history':{
			html:'<li class="category02">' +
					'<input type="radio" name="reserve-select-category-history" id="reserve-select-category02-history">' +
					'<label for="reserve-select-category02-history">' +
						'<div class="image">' +
							'<img src="/mobile/img/detail/img_reserve_select_sp_02.png" alt="ドライブスルー">' +
						'</div>' +
						'<div class="category-title">' +
							'<p class="icon">店外</p>' +
							'<p class="title">ドライブスルー</p>' +
						'</div>' +
					'</label>' +
				'</li>',
			target:true,
		},

		'reserve-select-category03-history':{
			html:'<li class="category03">' +
					'<input type="radio" name="reserve-select-category-history" id="reserve-select-category03-history">' +
					'<label for="reserve-select-category03-history">' +
						'<div class="image">' +
							'<img src="/mobile/img/detail/img_reserve_select_sp_03.png" alt="駐車場で受取る">' +
						'</div>' +
						'<div class="category-title">' +
							'<p class="icon">店外</p>' +
							'<p class="title">駐車場で受取る</p>' +
						'</div>' +
					'</label>' +
				'</li>',
			target:true,
		},

		'reserve-select-category04-history':{
			html:'<li class="category04">' +
					'<input type="radio" name="reserve-select-category-history" id="reserve-select-category04-history">' +
					'<label for="reserve-select-category04-history">' +
						'<div class="image">' +
							'<img src="/mobile/img/detail/img_reserve_select_sp_04.png" alt="宅配">' +
						'</div>' +
						'<div class="category-title">' +
							'<p class="icon">店外</p>' +
							'<p class="title">宅配</p>' +
						'</div>' +
					'</label>' +
				'</li>',
			target:true,
		},
	}

	if (historyFlag) {
		return btnHistoryList;
	} else {
		return btnList;
	}

};

/**
 * 2020.09.25 #12457 予約方法の選択 前回の予約履歴と切り分け
 * @method renderSelectionReserve
 */
EPK.Pref.renderSelectionReserveHistory = function(shopId, useShohoFlag, driveThrough, parkingReceive, sameModal ,modalId, onlineMedicalguidance, contractorDelivery, visitToPharmacist, deliveryReserveByPatient) {

	EPK.Pref.createSelectModalHistory(shopId, useShohoFlag, driveThrough, parkingReceive, sameModal, contractorDelivery, visitToPharmacist, deliveryReserveByPatient);

	// 通常予約を優先選択
	$('[class^=toform-reserve-select-category]').hide();
	$('#reserve-select-category01-history').prop('checked', true);
	$('.toform-reserve-select-category01-history').show();

	// ラジオボタンが１つ以下選択できる状態なら帯を削除
	if ($('#modal-reserve-select-history .not-support-filter').length < 3) {
		$('#modal-reserve-select-history .login-box').closest('.select-wrap').prepend('<h3>予約方法を選択してください</h3>');
	}
	//予約方法の選択
	$('[name=reserve-select-category-history]').on('change', function(event) {
		var targetId = event.target.id;

		$('[class^=toform-reserve-select-category]').hide();
		$('.toform-' + targetId).show();

		if (!EPK.common.isLogin) {
			if (targetId == 'reserve-select-category01') {
				$('#select-popup-reserve-box').hide();
				$('#select-popup-no-reserve-box').show();
			} else {
				$('#select-popup-reserve-box').show();
				$('#select-popup-no-reserve-box').hide();
			}
		}
	});

	// 予約フォームへ進むのクリックイベントを付与
	// click.reserveSelectModal
	// モーダル表示
	$('[class^=toform-reserve-select-category]').off('click.reserveSelectModal');
	$('.toform-reserve-select-category03-history').on('click.reserveSelectModal', function() {
		$('#modal-parking-reserve-history .reserve a').attr('href', '/shoho/monshin?pharmacy_id=' + shopId + '&receive_mode=parking');
		if (EPK.common.isLogin) {
			$('#modal-parking-reserve-history .reserve a').removeClass().addClass('w-btn GA_detail_parking_login_' + shopId);
		} else {
			$('#modal-parking-reserve-history .reserve a').removeClass().addClass('w-btn GA_detail_parking_submit_' + shopId);
		}
		EPK.Pref.openPopup('#modal-parking-reserve-history', {});
		return false;
	});

	// 0410 廃止対応：ビデオチャット対応時のみ予約Modal表示。それ以外はオン診へ誘導
	if (deliveryReserveByPatient && visitToPharmacist) {
		itemTemplateSrc='#modal-remote-approval-history-has-prescription';
	} else {
		itemTemplateSrc='#modal-online-history-consul';
	}

	$('.toform-reserve-select-category04-history').on('click.reserveSelectModal', function() {
		EPK.Pref.openPopup(itemTemplateSrc, {
			open: function() {
				$('#modal-remote-approval-history-has-prescription .next-button a').off('click.hasPrescription2');
				$('#modal-remote-approval-history-has-prescription .next-button a').on('click.hasPrescription2', function() {
					EPK.Pref.openPopup('#modal-remote-approval-history-has-prescription2', {
						open: function() {
							$('#modal-remote-approval-history-has-prescription2 .next-button a').off('click.hasPrescription');
							$('#modal-remote-approval-history-has-prescription2 .next-button a').on('click.hasPrescription', function() {

								EPK.Pref.openPopup('#modal-remote-approval-history', {
									open: function() {
										$('#modal-remote-approval-history').find('.agree-checkbox input[type=checkbox]')
										.prop('checked', false)
										.off('change.toFormCheckbox')
										.on('change.toFormCheckbox', function() {
											if ($(this).prop('checked')) {
												$('#modal-remote-approval-history').find('.login-button').removeClass('is-disabled');
											} else {
												$('#modal-remote-approval-history').find('.login-button').addClass('is-disabled');
											}
										});
										$('#modal-remote-approval-history').find('.login-button').addClass('is-disabled');
										$('#modal-remote-approval-history').find('.login-button a')
										.off('click.toForm')
										.on('click.toForm', function(e) {
											if ($(this).closest('.login-button').hasClass('is-disabled')) {
												e.preventDefault();
											}
										});
									}
								});
								return false;
							});
						}
					});
					return false;
				});
			}
		});
		return false;
	});
	$('.toform-reserve-select-category01-history,.toform-reserve-select-category02-history').on('click.reserveSelectModal', function(e) {
		//クリック時の値を保存
		EPK.Pref.gaCategorySubmitBtnFromDetailToShoho = e.currentTarget.getAttribute('ga-category');
		EPK.Pref.selectedDriveThrough = e.currentTarget.classList.contains('select_drive_through') ? 1 : 0;
		EPK.Pref.doLogin = e.currentTarget.classList.contains('do_login') ? 1 : 0;
		EPK.Pref.doSignup = e.currentTarget.classList.contains('do_signup') ? 1 : 0;
		EPK.Pref.slide = e.currentTarget.getAttribute('data-slide');
		var event = e;
		EPK.Pref.openCalenderHistory(event,shopId);
		return false;
	});
};

EPK.Pref.openCalenderHistory = function (event,shopId,category01Only) {

	var targetEl;
	var modalUrl = '#modal-select-daytime-'+ shopId + '-history';
	if (category01Only) {
		//event 対象のサービスが一つならcategory01の注意事項を確認して進む
		targetEl = event;
		EPK.Pref.gaCategorySubmitBtnFromDetailToShoho = targetEl.getAttribute('ga-category');
		EPK.Pref.selectedDriveThrough = targetEl.classList.contains('select_drive_through') ? 1 : 0;
		EPK.Pref.slide = targetEl.getAttribute('data-slide');
	} else {
		//event clickした要素
		EPK.Pref.gaCategorySubmitBtnFromDetailToShoho = event.currentTarget.getAttribute('ga-category');
		EPK.Pref.selectedDriveThrough = event.currentTarget.classList.contains('select_drive_through') ? 1 : 0;
		EPK.Pref.slide = event.currentTarget.getAttribute('data-slide');
		targetEl = event.currentTarget;
	}

	var modalUrl = 'modal-select-daytime-'+ shopId + '-history';
	if (targetEl.getAttribute('data-day')) {
		var targetday = targetEl.getAttribute('data-day');
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#'+modalUrl},
			type: 'inline',
			callbacks:{
				open:function () {
					//カレンダーの初期化
					EPK.Pref.initCalender(shopId,targetday,modalUrl);

					//カレンダーのみの時のイベント計測
					if (category01Only) {
						eventLabel = 'list' + '_' + shopId + '_viewCategory01';
						dataLayer.push({'event':'reserve_select_category_imp','action': eventLabel} );
					}
				},
				close:function () {
					//クローズ処理　イベント、エラー等の初期化
					EPK.Pref.jsErrorText = '';
					EPK.Pref.selectTimeHidden.value = undefined;
					EPK.Pref.submitFlag = false;
					if (EPK.Pref.timeElem.length > 0) {
						[].forEach.call(EPK.Pref.timeElem,function (time) {
							if (time.checked) {
								time.checked = false;
							}
							time.removeEventListener('click',EPK.Pref.timeClick);
						})
					}
					EPK.Pref.daySelect.removeEventListener('change',EPK.Pref.daySelectEvent);
					EPK.Pref.daySelect.selectedIndex = 0;
				}
			}
		}, 0);
	}
};

/**
 * #12457 #12715
 * 予約方法の選択 前回の予約履歴 ポップアップの生成
 * @method createSelectModal
 */
EPK.Pref.createSelectModalHistory = function (shopId, useShohoFlag, driveThrough, parkingReceive, sameModal, contractorDelivery, visitToPharmacist, deliveryReserveByPatient) {
	var selectList = document.querySelector('#modal-reserve-select-history div.select-icon-list');
	var openPopup = function () {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#modal-reserve-select-history'},
			type: 'inline',
			callbacks:{
				open:function () {
					//#12715 予約方法の選択イベント計測
					var modal = document.getElementById('modal-reserve-select-history');
					var categoryArr;
					var labelArr = [];
					var labelJoin,eventLabel;
					if (modal) categoryArr = modal.querySelectorAll('input[name^="reserve-select-category"]');

					if (categoryArr.length > 0) {
						for (var i = 0; i < categoryArr.length; i++) {
							var label = categoryArr[i].id.replace('reserve-select-category','');
							if (label) label = label.replace('-history','');
							labelArr.push(label);
						}
						if (labelArr.length == 0) return;
						labelJoin = labelArr.join(' ');
						eventLabel = 'list' + '_' + shopId + '_viewCategory' + labelJoin;
						dataLayer.push({'event':'reserve_select_category_imp','action': eventLabel} );
					}
				}
			}
		}, 0);
	}
	selectList.innerHTML = '';
	var targetgetBtnIdList = [
		'reserve-select-category01-history',
		'reserve-select-category02-history',
		'reserve-select-category03-history',
		'reserve-select-category04-history',
	];
	var btnlist = EPK.Pref.renderBtnList(true);
	var renderTarget = [];
	var disabled;
	// 選択可不可判定
	$.each(targetgetBtnIdList, function(index, id) {
		if (id == 'reserve-select-category01-history') {
			// 通常予約
			disabled = !(useShohoFlag);
		} else if (id == 'reserve-select-category02-history') {
			// ドライブスルー
			disabled = !(useShohoFlag && driveThrough);
		} else if (id == 'reserve-select-category03-history') {
			// 駐車場で受取る
			disabled = !(useShohoFlag && parkingReceive);
		} else if (id == 'reserve-select-category04-history') {
			// 電話服薬指導予約
			//disabled = !(coronaFlag);
			// disabled = !(deliveryReserveByPatient && (contractorDelivery || visitToPharmacist));
			// 20952:暫定対応業者配送のみの場合はNG
			if (EPK.common.isSP) {
				disabled = true;
			}else{
				disabled = !(deliveryReserveByPatient && visitToPharmacist);
			}
		}

		if (disabled) {
			btnlist[id].target = false;
		} else {
			renderTarget.push(id);
		}
	});

	var ulOne,ulTwo;
	if (renderTarget.length == '1' ) {

		var category01 = document.getElementById('modal-reserve-select-history');
		var category01Btn
		if (category01) {
			category01Btn = category01.querySelector('a.toform-reserve-select-category01-history');
		}
		//対象サービス1つの場合はカレンダーを表示
		EPK.Pref.openCalenderHistory(category01Btn,shopId,true);

	} else if (renderTarget.length == '2') {

		ulOne = document.createElement('ul');
		ulOne.innerHTML = btnlist[renderTarget[0]].html + '\n' + btnlist[renderTarget[1]].html;
		selectList.insertAdjacentElement('beforeend',ulOne);
		openPopup();

	} else if (renderTarget.length == '3') {

		ulOne = document.createElement('ul');
		ulOne.innerHTML = btnlist[renderTarget[0]].html;
		ulTwo = document.createElement('ul');
		ulTwo.innerHTML = btnlist[renderTarget[1]].html + '\n' + btnlist[renderTarget[2]].html;
		selectList.insertAdjacentElement('beforeend',ulOne);
		selectList.insertAdjacentElement('beforeend',ulTwo);
		openPopup();

	} else if (renderTarget.length = '4') {

		ulOne = document.createElement('ul');
		ulOne.innerHTML = btnlist[renderTarget[0]].html + '\n' + btnlist[renderTarget[1]].html;
		ulTwo = document.createElement('ul');
		ulTwo.innerHTML = btnlist[renderTarget[2]].html + '\n' + btnlist[renderTarget[3]].html;
		selectList.insertAdjacentElement('beforeend',ulOne);
		selectList.insertAdjacentElement('beforeend',ulTwo);
		openPopup();
	}
};

/**
 * #18366 発券機の待ち時間C側対応
 * 発券機対応店舗での待ち時間がある場合に受取り希望時間を加工(削除)する
 * @method initCalenderTicketPermited
 */
EPK.Pref.ticket_permited = false;
EPK.Pref.initCalenderTicketPermited = function (shopId,day,modalUrl) {
	$.ajax({
		type: 'get',
		url: '/api/detail/get_ticket_permited_waiting_time',
		data: {"shopId": shopId},
		cache: false,
		dataType: 'json',
		timeout: 100000
	}).done(function(data){
		if (data['result'] == 'success') {
			if (data['waiting_time'] > 0) {
				// 待ち時間
				var additionalTime = data['waiting_time'] * 60 * 1000;
				var now = new Date();
				now = now.setTime(now.getTime() + (additionalTime));
				var addDate = new Date(now);
				var addHM = ('00' + addDate.getHours()).slice(-2) + ':' + ('00' + addDate.getMinutes()).slice(-2);

				// 選択できない受取り時間を削除
				var modal = document.getElementById('modal-select-daytime-'+shopId);
				var selectTimeList = modal.querySelector('.select-time-list');
				var selectDayTime = selectTimeList.querySelectorAll('input[name="select-time"]');
				if (selectDayTime.length > 0) {
					[].forEach.call(selectDayTime,function (elm) {
						if (elm.value == '今すぐ' || elm.value < addHM) {
							//$('#' + elm.id).parents('li').remove();
						}
					});
				}
			}
		}
	}).fail(function(data){
		//何もしない
	}).always(function(data){
		$('#modal-select-daytime-' + shopId).find('.select-box-time').show();	// 隠した要素を再表示
		EPK.Pref.ticket_permited = true;
		EPK.Pref.initCalender(shopId,day,modalUrl);
	});
}

/**
 * #12457　受付、ドライブスルー　カレンダー対応　
 * @method initCalender
 */
EPK.Pref.initCalender = function (shopId,day,modalUrl) {

	// #18366 発券機の待ち時間C側対応
	var ticket_permited = 0;
	var ready_email_no_send_flag = 0;
	if (modalUrl && modalUrl.indexOf('history') != '-1') {
		// 予約履歴は再取得しない
	} else {
		ticket_permited = EPK.Pref.shops[shopId]['ticket_permited'];
		ready_email_no_send_flag = EPK.Pref.shops[shopId]['ready_email_no_send_flag'];	// 総合管理API: screen_designが同期されている 0:旧デザイン（～2018/4）1:リニューアル版（2018/4～）
	}
	if (ticket_permited > 0 && ready_email_no_send_flag && !EPK.Pref.ticket_permited) {
		$('#modal-select-daytime-' + shopId).find('.select-box-time').hide();	// 初回表示時にレイアウトが崩れる(非同期待ち中に全受取り時間が表示される)場合があるので隠す
		EPK.Pref.initCalenderTicketPermited(shopId,day,modalUrl);
		return;
	}
	EPK.Pref.ticket_permited = false;

	//クリックした店舗のモーダルから情報を取得
	EPK.Pref.modal = document.getElementById('modal-select-daytime-'+shopId);
	EPK.Pref.sameModalFlag = false;
	if (modalUrl && modalUrl.indexOf('history') != '-1') {
		EPK.Pref.modal = document.getElementById('modal-select-daytime-'+shopId+'-history');
		EPK.Pref.sameModalFlag = true;
	}
	EPK.Pref.dayOk = 'select-day-ok';
	EPK.Pref.dayNg = 'select-day-ng';
	EPK.Pref.submitFlag = false;
	EPK.Pref.daySelect = EPK.Pref.modal.querySelector('[id*="select-day-' + shopId + '"]');
	EPK.Pref.daySelectLabel = EPK.Pref.modal.querySelector('.select-box-day label span');
//	EPK.Pref.Rev = EPK.Pref.modal.querySelector('.rev');
//	EPK.Pref.Fwd = EPK.Pref.modal.querySelector('.fwd');
	EPK.Pref.selectTime = EPK.Pref.modal.querySelectorAll('.select-time-list');
	EPK.Pref.selectTimeHidden = EPK.Pref.modal.querySelector('[id*="select-time-hidden-' + shopId + '"]');
	EPK.Pref.selectTimeSubtext = EPK.Pref.modal.querySelector('.sup-text');
	EPK.Pref.selectTimeSubtextAsSoonAs = EPK.Pref.modal.querySelector('.select-time-subtext-as-soon-as');
	EPK.Pref.selectTimeList = EPK.Pref.modal.querySelector('.select-time-list');
	EPK.Pref.selectNoReserveBox = EPK.Pref.modal.querySelector('.select-no-reserve-box');
	EPK.Pref.submitBtn = EPK.Pref.modal.querySelectorAll('[class*="calendar_submit_btn_' + shopId + '"]');
	EPK.Pref.submitBtnBox = EPK.Pref.modal.querySelector('[id^=modal-select-daytime-] .login-box');
	EPK.Pref.jsErrorText = EPK.Pref.modal.querySelector('[id*="js-error-text-' + shopId + '"]');
	EPK.Pref.getDays = EPK.Pref.modal.querySelectorAll('ul.select-time-list');
	EPK.Pref.timeElem = EPK.Pref.modal.querySelectorAll('[name="select-time"]');
	EPK.Pref.targetShopId = shopId;
	var day = day;
	var reserveOKDay;
	EPK.Pref.SelectInitialize();

	//カレンダーsubmitbtn用classの付与
	var submitBtn = $('[class*="calendar_submit_btn_' + shopId + '"]');
	if (submitBtn) {
		submitBtn.removeClass('GA_reserve').removeClass('GA_drivethru');
		if (EPK.Pref.doLogin) {
			if (EPK.Pref.selectedDriveThrough) {
				submitBtn.addClass('GA_drivethru');
			} else {
				submitBtn.addClass('GA_reserve');
			}
		} else {
			if (EPK.Pref.selectedDriveThrough) {
				submitBtn.addClass('GA_drivethru');
			} else {
				submitBtn.addClass('GA_reserve');
			}
		}
	}

	function getSelectVal(today) {
		var selectVal = '';
		if (EPK.Pref.daySelect.length > 0) {
			for (var i = 0; i < EPK.Pref.daySelect.length; i++) {
				var value = EPK.Pref.daySelect[i].value;
				if (value.match(/1-/)) {
					selectVal = value;
					break;
				}
			}
		}
		if (selectVal.length > 0) {
			return selectVal;
		}
		return '0-' + today;
	}
	function splitDay(tody) {
		var selectVal = tody;
		var selectValSpl = selectVal.split('-');
		return selectDay = selectValSpl[1];
	}


	// 選択済み日時が選択不可だった場合は、選択できる時間のある日付
	reserveOKDay = getSelectVal(day);
	EPK.Pref.daySelect.value = reserveOKDay;
	day = splitDay(reserveOKDay);

	//日付スライドの初期化
	EPK.Pref.reserveScheduleSwiperInitialize(EPK.Pref.slide);

	//セレクトボックスの日付の時間を描画
	var initDayTime;
	if (EPK.Pref.sameModalFlag) {
		initDayTime = document.querySelector('[id*="day'+day+'"]').querySelectorAll('input[name="select-time"]');
	} else {
		initDayTime = document.getElementById('day'+day).querySelectorAll('input[name="select-time"]');
	}
	if (initDayTime.length > 0) {
		EPK.Pref.SelectOkDay(day,shopId);
		EPK.Pref.daySelectLabel.classList.add(EPK.Pref.dayOk);
		EPK.Pref.daySelectLabel.classList.remove(EPK.Pref.dayNg);
		EPK.Pref.daySelectLabel.innerHTML = '○';
	} else {
		EPK.Pref.daySelectLabel.classList.add(EPK.Pref.dayNg);
		EPK.Pref.daySelectLabel.classList.remove(EPK.Pref.dayOk);
		EPK.Pref.daySelectLabel.innerHTML = '×';
		EPK.Pref.SelectNgDay();
	}

	//日付変更イベント
	EPK.Pref.daySelect.addEventListener('change',EPK.Pref.daySelectEvent);

	//時間選択イベント
	if (EPK.Pref.timeElem.length > 0) {
		[].forEach.call(EPK.Pref.timeElem,function (time) {
			EPK.Pref.timeSelectEvent(time);
		});
	}

	//決定ボタンのイベント
	if (EPK.Pref.submitBtn.length > 0) {
		[].forEach.call(EPK.Pref.submitBtn,function (btnEl) {
			btnEl.addEventListener('click', EPK.Pref.submitEvent);
		});
	}
};

/**
 * #12457　受付、ドライブスルー　カレンダー対応
 * 決定ボタンイベント
 * @method submitEvent
 */
EPK.Pref.submitEvent = function () {
	//submitBtn

	if (EPK.Pref.submitFlag) {
		// GA
		var gaCategory = EPK.Pref.gaCategorySubmitBtnFromDetailToShoho ? EPK.Pref.gaCategorySubmitBtnFromDetailToShoho : 2;
		dataLayer.push({'event': 'checkvacancy','action': '予約ボタン','category': gaCategory,'label': EPK.Pref.targetShopId });

		var addStrParams = '';
		var selectedTime = EPK.Pref.selectTimeHidden.value;
		addStrParams += '&selected_date=' + selectedTime;

		// 2020.09.29 #12457 予約方法の選択
		if (EPK.Pref.selectedDriveThrough && EPK.Pref.selectedDriveThrough == 1) {
			addStrParams += '&is_drive_through=1';
		}

		if (this.classList.contains('do_login')) {
			addStrParams += '&do_login=1';
		}
		if (this.classList.contains('do_guest')) {
			addStrParams += '&do_guest=1';
		}

		location.href = '/shoho?pharmacy_id=' +  EPK.Pref.targetShopId + addStrParams;
	}else{
		if (EPK.Pref.selectTimeHidden.value) {
			EPK.Pref.jsErrorText.innerHTML = '時刻を選択してください。';
			return;
		}
	}
}

/**
 * #12457　受付、ドライブスルー　カレンダー対応
 * 時間選択イベントの登録
 * @method timeSelectEvent
 */
EPK.Pref.timeSelectEvent = function (time) {
	time.addEventListener('click',EPK.Pref.timeClick);
}

/**
 * #12457　受付、ドライブスルー　カレンダー対応
 * 時間選択イベント
 * @method timeClick
 */
EPK.Pref.timeClick = function (e) {
	var id = e.currentTarget.id.replace('t','');
	if (EPK.Pref.sameModalFlag) {
		id = id.replace('-history','');
	}
	if (id && id.indexOf('-'+EPK.Pref.targetShopId != '-1')) {
		id = id.replace('-'+EPK.Pref.targetShopId,'');
	}
	if (EPK.Pref.selectTimeHidden && id) {
		EPK.Pref.selectTimeHidden.value = id;
		EPK.Pref.submitFlag = true;
//		EPK.Pref.submitBtn.classList.remove('disabled');
	}
}

/**
 * #12457　受付、ドライブスルー　カレンダー対応
 * 日付選択イベント
 * @method daySelectEvent
 */
EPK.Pref.daySelectEvent = function () {

	var selectVal = EPK.Pref.daySelect.value;
	var selectValSpl = selectVal.split('-');
	var selected = selectValSpl[0];
	var selectDay = selectValSpl[1];
	var shopId = EPK.Pref.targetShopId;

	var selectDayTime;
	if (EPK.Pref.sameModalFlag) {
		selectDayTime = document.querySelector('[id*="day'+selectDay+'"]').querySelectorAll('input[name="select-time"]');
	} else {
		selectDayTime = document.getElementById('day'+selectDay).querySelectorAll('input[name="select-time"]');
	}
	if (selectDayTime.length > 0) {
		EPK.Pref.SelectOkDay(selectDay,shopId);
		if (EPK.Pref.sameModalFlag) {
			EPK.Pref.modal.querySelector('ul.select-time-list[id*="day'+selectDay+'"]').style.display = 'flex';
		} else {
			document.getElementById('day'+selectDay).style.display = 'flex';
		}
		EPK.Pref.daySelectLabel.classList.add(EPK.Pref.dayOk);
		EPK.Pref.daySelectLabel.classList.remove(EPK.Pref.dayNg);
		EPK.Pref.daySelectLabel.innerHTML = '○';
	} else {
		EPK.Pref.SelectNgDay();
		EPK.Pref.daySelectLabel.classList.add(EPK.Pref.dayNg);
		EPK.Pref.daySelectLabel.classList.remove(EPK.Pref.dayOk);
		EPK.Pref.daySelectLabel.innerHTML = '×';
	}
}

/**
 * #12457　受付、ドライブスルー　カレンダー対応
 * 日付チェック　OKパターン
 * @method SelectOkDay
 */
EPK.Pref.SelectOkDay = function(day,shopId) {
//	var selectTodayTimeList = document.querySelector('#day' + day);
	var selectTodayTimeList = EPK.Pref.modal.querySelector('ul.select-time-list[id*="day' + day + '"]');
	EPK.Pref.SelectInitialize();
//	EPK.Pref.Rev.style.display = '';
//	EPK.Pref.Fwd.style.display = '';
	selectTodayTimeList.style.display = '';
	if(selectTodayTimeList.querySelector('input[id^="tnow"]')) {
		EPK.Pref.selectTimeSubtextAsSoonAs.style.display = '';
	}
	EPK.Pref.submitBtnBox.style.display = '';
	EPK.Pref.selectTimeSubtext.style.display = '';
};

/**
 * #12457　受付、ドライブスルー　カレンダー対応
 * 日付チェック　NGパターン
 * @method SelectNgDay
 */
EPK.Pref.SelectNgDay = function() {
	EPK.Pref.SelectInitialize();
	EPK.Pref.selectNoReserveBox.style.display = '';
};

/**
 * #12457　受付、ドライブスルー　カレンダー対応
 * カレンダー内の初期化
 * @method SelectInitialize
 */
EPK.Pref.SelectInitialize = function() {
//	EPK.Pref.Rev.style.display = 'none';
//	EPK.Pref.Fwd.style.display = 'none';
//	EPK.Pref.selectTimeList.style.display = 'none';
	EPK.Pref.selectTimeSubtext.style.display = 'none';
	EPK.Pref.selectTimeSubtextAsSoonAs.style.display = 'none';
	EPK.Pref.selectNoReserveBox.style.display = 'none';
	EPK.Pref.submitBtnBox.style.display = 'none';
	EPK.Pref.jsErrorText.textContent = '';
	EPK.Pref.selectTimeHidden.value = undefined;
	EPK.Pref.submitFlag = false;
	var replaceFlag
//	EPK.Pref.submitBtn.classList.add('disabled');

	for (var i = 0; i < EPK.Pref.selectTime.length; i++) {

		if (EPK.Pref.selectTime[i].querySelectorAll('li').length == 0) {
			var noTimeDay = EPK.Pref.selectTime[i].id.replace('day','');

			if (noTimeDay && noTimeDay.indexOf('-history') != '-1') {
				noTimeDay = noTimeDay.replace('-history','');
			}

			if (noTimeDay) {
				var targetEl = EPK.Pref.daySelect.querySelector('[value="1-'+noTimeDay+'"]');
				if (targetEl && targetEl.value.indexOf('1-') != '-1') {
					targetEl.value = targetEl.value.replace('1-','0-');
				}
			}
		}
	}

	for (var i = 0; i < EPK.Pref.getDays.length; i++) {
		EPK.Pref.getDays[i].style.display = 'none';
	}
};

/**
 * #12457　受付、ドライブスルー　カレンダー対応
 * 日付スライダー
 * @method SelectInitialize
 */
EPK.Pref.reserveScheduleSwiperInitialize  = function(slide) {
	var shopRevDays = [];
	if (EPK.Pref.getDays.length > 0) {
		for (var i = 0; i < EPK.Pref.getDays.length; i++) {
			shopRevDays.push(EPK.Pref.getDays[i].id.replace(/day/g, ''));
		}
	}
	[].forEach.call(shopRevDays, function(element) {
		var swiper = $('#reserve-schedule-day' + element).swiper({
			centeredSlides: true,
			initialSlide: slide,
			slidesPerView: 'auto',
			loop: false,
		});
	});
};

EPK.Pref.openApprovalPopup = function(shop_id) {
	$('#modal-remote-approval .agreement a').attr('href', '/remote/shoho?pharmacy_id=' + shop_id);
	EPK.Pref.openPopup("#modal-remote-approval", {});
}

EPK.Pref.openPopup = function(src, callbacks) {
	$.magnificPopup.close();
	$.magnificPopup.open({
		items: {src: src},
		type: 'inline',
		//modal: true,
		callbacks: callbacks,
	}, 0);
}
